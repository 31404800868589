<template>
  <div class="the-privacy">
    <h2 class="heading">GLOCAL PRIVACY NOTICE</h2>
    <div class="titles">
      <h2>WHAT DOES THE PRIVACY NOTICE COVER?
        <span>1</span>
      </h2>
      <h2>WHAT PERSONAL INFORMATION DOES GLOCAL COLLECT?
        <span>1</span>
      </h2>
      <h2>HOW DOES GLOCAL USE YOUR PERSONAL INFORMATION?
        <span>3</span>
      </h2>
      <h2>
        WHO DOES GLOCAL SHARE YOUR PERSONAL INFORMATION WITH?
        <span>3</span>
      </h2>
      <h2>
        HOW LONG DOES GLOCAL RETAIN YOUR PERSONAL INFORMATION?
        <span>3</span>
      </h2>
      <h2>
        HOW DOES GLOCAL KEEP YOUR PERSONAL INFORMATION SAFE?
        <span>4</span>
      </h2>
      <h2>WHAT ARE YOUR PRIVACY RIGHTS?<span>4</span></h2>
    </div>
    <div class="details">
      <h3>WHAT DOES THE PRIVACY NOTICE COVER?</h3>
      <p>
        The purpose of this Privacy Notice is to explain how Glocal Posts LLC.
        (“Glocal”, “we”, “us”, or “our”), secures, shares, and uses personal
        information that it collects and receives from content creators,
        purchasers, viewers, and moderators through its website glocal.com,
        communication tools, and marketing services (“Services”). Our Privacy
        Notice is not just a requirement but an opportunity to build trust and
        understanding with all those who use our Services.
      </p>
    </div>
    <div class="details">
      <h3>WHAT PERSONAL INFORMATION DOES GLOCAL COLLECT?</h3>
      <p>
        Glocal may collect personal information as further described below
        through: (a) Personal Information provided by you, (b) Automatically
        Collected Information, (c) Billing Information, (d) Cookies and Other
        Tracking Technologies, and (e) Third Party Analytics Data. We do not
        offer or sell to any third party any data based on consumer information.
      </p>
      <ul>
        <h5>(a) Personal Information provided by you:</h5>
        <li>Full Name / Pseudonym</li>
        <li>Physical address</li>
        <li>Phone Number</li>
        <li>Email address</li>
        <li>Date of Birth</li>
        <li>Username</li>
        <li>Credit Card Information</li>
        <p>
          We use this personal information in order to provide you with our
          Services, to respond to billing inquiries, verify your identity, and
          keep you updated with news about Glocal, the Services, and our
          partners in accordance with your consent preferences as applicable.
        </p>
      </ul>
      <h5>
        (b) Personal Information provided by you:
        <span>
          When you access and use our Service, we automatically collect and
          store, in log files, your Internet Protocol (IP) address, browser
          type, internet service provider (ISP), referring/exit pages, operating
          system, date/time stamp, clickstream data, and other user information
          as part of our Service analytics (individually and collectively,
          “Automatically Collected Information”). We do not link this
          Automatically Collected Information to other information we collect
          about you and such Automatically Collected Information does not
          directly identify you as a person. However, where you are a resident
          of the European Union, this may still be deemed to be personal
          information.
        </span>
      </h5>
      <h5>
        (c) Billing Information.
        <span>
          When you sign up for a paid account with us, we require your billing
          information in order to process the transaction. Billing information
          includes your name, address, telephone number, credit card details,
          PayPal email address, and other information necessary to process the
          transaction. We will retain the last 4 digits of your credit card
          number, credit card type, name, address, and some other billing
          information, so that you may identify that card used in the future.
          Glocal does not store your full credit card number or CVV2; this
          information is discarded after your transaction has been
          processed.
        </span>
      </h5>
      <h5>
        (d) Cookies and Tracking Technologies.
        <span>
          Glocal may use cookies, pixels, and similar technologies for
          analyzing trends as well as for site administration. We may receive
          reports based on the use of these technologies by third party
          analytics companies on an individual, device specific and/or in an
          aggregated basis.
        </span>
      </h5>
      <h5 class="uppercase">
        UNLESS YOU ARE AN EU RESIDENT, VISITING GLOCAL WITH YOUR BROWSER SET TO
        ACCEPT COOKIES OR OTHER SOFTWARE TELLS US YOU WANT TO USE OUR SERVICE
        AND YOU CONSENT TO OUR USE OF COOKIES AND OTHER TECHNOLOGIES TO PROVIDE
        YOU OUR SERVICE.
      </h5>
      <p>
        You can control the use of certain cookies at the individual browser
        level or in your operating system preferences. If you opt-out of such
        cookies, you may still use our Service, but your ability to use some
        features or areas of our Service may be limited. You can find out more
        about your rights in relation to cookies and how to control them here:
        <a>www.youronlinechoices.com.</a>
      </p>
      <h5>
        Sensitive Consumer Information.
        <span>
          Glocal does not collect or use “sensitive consumer information” and
          does not offer or sell to any third party any data based on sensitive
          consumer information. For purposes of this Privacy Notice, “Sensitive
          consumer information” includes social security numbers or other
          government-issued identifiers, insurance plan numbers, financial
          account numbers, and precise information about past, present, or
          potential future health or medical conditions or treatments, including
          genetic, biometric, genomic and family medical history nor does it
          include racial or ethnic origin information, information about sexual
          orientation, criminal records or trade union membership information.
          Glocal maintains internal policies and procedures designed to
          safeguard against the collection or use of such information.
        </span>
      </h5>
    </div>
    <div class="details">
      <h3>HOW DOES GLOCAL USE YOUR PERSONAL INFORMATION?</h3>
      <p>
        As further described below, Glocal may use the information it collects
        from you for any lawful purpose, including, without limitation, for
        providing products and services, identification and authentication,
        Service operation, analytics and improvements, support, contact (e.g.,
        sending notifications related to use of the Service), marketing and
        promotions, research, and anonymous reporting. We may combine
        Automatically Collected Information with other information we collect
        about you, in each case that does not directly identify you as a person
        (but as explained above, may still identify your device or indirectly
        identify you), to improve site functionality and the services we offer
        you, and for Service and both internal and external marketing analytics.
      </p>
      <p>
        Using information we collect from you will be limited to the following
        instances:
      </p>
      <ol type="1">
        <li>
          Where we have to provide a requested service or carry out a contract
          with you
        </li>
        <li>
          Where we have your consent (Used mainly for marketing communications)
        </li>
        <li>
          Where we have a legitimate interest (Used to prevent fraud, diagnose
          problems with the technology, determine user status etc.)
        </li>
        <li>Where we have a legal obligation</li>
      </ol>
    </div>
    <div class="details">
      <h3>WHO DOES GLOCAL SHARE YOUR PERSONAL INFORMATION WITH?</h3>
      <p>
        Glocal does not share personal information we learn about you from your
        use of the Service with others except as described in this Privacy
        Notice and in connection with: (i) the completion of transactions; (ii)
        research, promotions, sweepstakes and contests; (iii) third party
        marketing; (iv) a merger or sale; (v) publicly accessible information;
        and (vi) other lawful disclosures.
      </p>
    </div>
    <div class="details">
      <h3>HOW LONG DOES GLOCAL RETAIN YOUR PERSONAL INFORMATION?</h3>
      <p>
        As further described below, we will retain your information for as long
        as your account is active or as needed to provide you the Service, but
        in no event longer than permitted by applicable law.
      </p>
      <ol type="1">
        <li>
          Glocal uses Automatically Collected Information for no more than 13
          months.
        </li>
        <li>
          We maintain stored data for a period of up to 24 months in order to
          comply with audits, court order or law enforcement inquiries and for
          the purpose of ensuring that our technology is functioning properly,
          and preventing fraud across our Service.
        </li>
        <li>
          After 24 months, all of the stored data is destroyed. Summarized data
          consisting of aggregated statistical data or other anonymous data may
          be kept indefinitely.
        </li>
      </ol>
      <ul>
        <p>
          When determining the relevant retention periods, we will take into
          account factors including:
        </p>
        <li>
          our contractual obligations and rights in relation to the personal
          information involved;
        </li>
        <li>
          legal obligation(s) under applicable law to retain data for a certain
          period of time
        </li>
        <li>statute of limitations under applicable law(s)</li>
        <li>(potential) disputes, and</li>
        <li>guidelines issued by relevant data protection authorities.</li>
        <p>
          Otherwise, we securely erase your information once this is no longer
          needed.
        </p>
      </ul>
    </div>
    <div class="details">
      <h3>HOW DOES GLOCAL KEEP YOUR PERSONAL INFORMATION SAFE?</h3>
      <p>
        We take reasonable steps to put in place appropriate physical,
        electronic, and procedural safeguards to protect the integrity and
        security of personal information about you and to prevent unauthorized
        or unlawful processing of personal information and the accidental loss,
        destruction, or damage to personal information. These measures include
        internal reviews of our data collection, storage and processing
        practices and security measures (Strong password protection and in
        certain areas industry standard SSL-encryption to protect data
        transmissions), as well as physical security measures to guard against
        unauthorized access to systems where we store data.
      </p>

      <p>
        However, data transmissions over the Internet and methods of electronic
        storage are not 100% secure. Consequently, we cannot guarantee or
        warrant the security of any information you transmit to us and you do so
        at your own risk.
      </p>
      <p>
        Glocal learns of a security systems breach we may attempt to notify you
        electronically so that you can take appropriate protective steps. We may
        post a notice on our applicable web sites if a security breach occurs.
        If this happens, you will need a web browser enabling you to view the
        applicable web sites. In these circumstances, we may also send an email
        to you at the email address you have provided to us. Depending on where
        you live, you may have a legal right to receive notice of a security
        breach in writing.
      </p>
    </div>
    <div class="details">
      <h3>WHAT ARE YOUR PRIVACY RIGHTS?</h3>
      <h5>
        Glocal as Data Controller.
        <span>
          Upon request, we will provide you with information about whether we
          hold or process your personal information on behalf of third parties.
          You can access and edit your account information with Glocal at any
          time by logging into your account. You can also contact us via
          info@glocal.com, and we will respond as soon as possible, but at most
          within 30 days.
        </span>
      </h5>
      <p>
        You can delete your applicable account by visiting the applicable
        account deletion page; however, some personal information, primarily
        your contact information, may remain in our records to the extent
        necessary to protect our legal interests or document compliance with
        regulatory requirements, in each case solely to the extent permitted by
        applicable law.
      </p>
      <p>
        If your personal information changes, or if you no longer wish to use
        our Service, you may correct, update, or delete such information by
        making the change on your user account page, or by submitting a request
        through info@glocal.com.
      </p>
      <p>
        I addition to contacting us via email, you also have the option of using
        the Glocal Help Center. We want you to be informed of, and in total
        control of the data you provide us. We’ve created the Help Center to
        guide you through the privacy settings and allow you to specifically opt
        in and opt out of certain data usages like cookies, marketing,
        analytics, and lookalike audiences.
      </p>
      <h5 class="m-0">Users in the European Union only:</h5>
      <p>
        Under EU Regulation 2016/679 of the European Parliament and the Council;
        the General Data Protection Regulation (“GDPR”), you have a number of
        rights when it comes to your personal information. Further information
        and advice about your rights can be obtained from the data protection
        regulator in your country of residence within the EU. You can exercise
        any of these rights by contacting us at info@glocal.com.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.the-privacy {
  padding: 10rem;

  .heading {
    font-size: 5rem;
    font-family: $font-secondary;
    text-align: center;
    margin-bottom: 3rem;
    text-transform: uppercase;
  }

  .titles {
    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  }

  .details {
    margin: 7rem 0;

    h3 {
      font-size: 4rem;
      font-family: $font-secondary;
      margin-bottom: 2rem;
    }

    p {
      font-family: $font-secondary;
      font-size: 1.8rem;
      margin-top: 1rem;
    }

    h5 {
      font-family: $font-secondary-bold;
      font-size: 1.8rem;
      margin-bottom: 2rem;

      span {
        font-family: $font-secondary;
      }
    }

    .m-0 {
      margin: 0;
    }

    .uppercase {
      text-transform: uppercase;
    }

    a {
      color: $color-faded-blue;
    }

    ul {
      li {
        font-family: $font-secondary;
        font-size: 1.8rem;
        margin-left: 2rem;
      }
    }

    ol {
      li {
        font-family: $font-secondary;
        font-size: 1.8rem;
        margin-left: 2rem;
      }
    }
  }
}
</style>